import {Module, Mutation, RegisterOptions, VuexModule} from "vuex-class-modules";
import jwtDecode from "jwt-decode";
import store from "../plugins/store";
import router from "../router/router";
import {menuStore} from "./menu-store";

const REQUEST_TOKEN = 'requestToken';
const TOKEN = 'token';
const LOGOUT = 'logout';
const TOKEN_EXPIRY = 'tokenExpiry';
const STAY_LOGGED_IN = 'stayLoggedIn';

export const DIALOG_SHOWN = true;
export const DIALOG_HIDDEN = false;
export const DIALOG_STAY_HIDDEN = null;

@Module
class LoginStore extends VuexModule {
    public jwtToken: string| null = null;
    public user: JwtUser | null = null;

    private delegates: JwtUserChangeHandler[] = [];
    private _stayLoggedIn: boolean | null = DIALOG_HIDDEN;


    constructor(options: RegisterOptions) {
        super(options);

        const sessionToken = sessionStorage.getItem(TOKEN);
        // If there is already a token in session storage
        if (sessionToken) {
            this.login(sessionToken);
        } else {
            // Request for token, if any other tab on the domain have it
            this.sendStorageEvent(REQUEST_TOKEN);
        }

        addEventListener('storage', (event: StorageEvent) => {
            const token = sessionStorage.getItem(TOKEN);

            // If another tab requested for a token... let's see if this tab has the token
            if (event.key === REQUEST_TOKEN && token) {
                this.sendStorageEvent(TOKEN, token);
            }
            // Some other tab sent the token
            else if (event.key === TOKEN && event.newValue) {
                store.commit('login-store/updateLogin', event.newValue);
            }
            else if (event.key === STAY_LOGGED_IN) {
                store.commit('login-store/updateStayLoggedIn', event.newValue);
            }
            // The user logged out on one of the tabs
            else if (event.key === LOGOUT) {
                store.commit('login-store/updateLogin', null);
                if (router.currentRoute.path !== '/') {
                    router.push('/');
                }
            }
        });
    }

    @Mutation
    public login(jwtToken: string) {
        this.updateUser(jwtToken);
        this.sendStorageEvent(TOKEN, jwtToken);
    }

    @Mutation
    public updateLogin(jwtToken: string | null) {
        this.updateUser(jwtToken);
    }

    @Mutation
    public updateStayLoggedIn(value: boolean | null) {
        this._stayLoggedIn = value;
        this.publishChange(this.user);
    }

    @Mutation
    public logout() {
        this.updateUser(null);
        this.sendStorageEvent(LOGOUT);
        if (router.currentRoute.path !== '/') {
            router.push('/');
        }
        menuStore.setMenuToMain();
    }

    public subscribe(changeHandler: JwtUserChangeHandler) {
        this.delegates.push(changeHandler);
    }

    public unsubscribe(changeHandler: JwtUserChangeHandler) {
        const index = this.delegates.indexOf(changeHandler);
        if (index > -1) {
            this.delegates.splice(index, 1);
        }
    }

    @Mutation
    setStayLoggedIn(value: boolean | null) {
        if (value !== this._stayLoggedIn) {
            this._stayLoggedIn = value;
            this.sendStorageEvent(STAY_LOGGED_IN, value);
        }
    }

    get stayLoggedIn(): boolean | null {
        return this._stayLoggedIn;
    }

    setTokenExpiry(value: number) {
        sessionStorage.setItem(TOKEN_EXPIRY, value.toString());
    }

    getTokenExpiry(): number {
        const expiry = sessionStorage.getItem(TOKEN_EXPIRY)
        return expiry ? +expiry : 0;
    }

    get isLoggedIn(): boolean {
        return this.user !== null;
    }

    private updateUser(jwtToken: string | null) {
        this.jwtToken = jwtToken;
        this.user = jwtToken ? jwtDecode(jwtToken) : null;

        if (jwtToken) {
            sessionStorage.setItem(TOKEN, jwtToken);
            this.calculateExpiry();
        } else {
            sessionStorage.removeItem(TOKEN);
            sessionStorage.removeItem(TOKEN_EXPIRY);
        }

        this._stayLoggedIn = DIALOG_HIDDEN;
        this.publishChange(this.user);
    }

    private calculateExpiry() {
        if (this.user) {
            const expirationTimestamp = this.user.exp * 1000;
            const currentTimestamp = Date.now();

            if (expirationTimestamp > currentTimestamp) {
                const expiryInSeconds = Math.ceil((expirationTimestamp - currentTimestamp) / 1000)
                sessionStorage.setItem(TOKEN_EXPIRY, expiryInSeconds.toString())
            }
        } else {
            sessionStorage.removeItem(TOKEN_EXPIRY);
        }
    }

    private publishChange(user: JwtUser | null) {
        this.delegates.forEach((delegate: JwtUserChangeHandler) => {
            delegate(user);
        });
    }

    private sendStorageEvent(key: string, value: any = Date.now()) {
        localStorage.setItem(key, value ? value.toString() : value);
        localStorage.removeItem(key);
    }
}

export interface JwtUser {
    sub: string;
    surname: string;
    givenname: string;
    iat: number;
    exp: number;
}

type JwtUserChangeHandler = (user: JwtUser | null) => void;

export const loginStore = new LoginStore({ store, name: "login-store" });
