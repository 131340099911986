const httpJsonp = (url: string, onFinish: () => void, data: any | null = null) => {
    if (!url) {
        throw new Error('url is necessary');
    }
    const callback: string = `CALLBACK${Math.random().toString().substring(9, 18)}`;
    const JSONP = document.createElement('script');
    JSONP.setAttribute('type', 'text/javascript');
    JSONP.setAttribute('onerror', `${callback}()`);

    const headEle = document.getElementsByTagName('head')[0];

    let ret = '';
    if (data) {
        if (typeof data === 'string') {
            ret = data;
        } else if (typeof data === 'object') {
            for (const key in data) {
                ret += `${key}=${encodeURIComponent(data[key])}`;
            }
        }
        ret += `_time=${Date.now()}`;
    }
    JSONP.src = `${url}?callback=${callback}${ret}`;
    return new Promise<any>((resolve, reject) => {
        (window as any)[callback] = (r: any) => {
            if (r === undefined) {
                reject("ERROR");
            } else {
                resolve(r);
            }
            headEle.removeChild(JSONP);
            delete (window as any)[callback];
            onFinish();
        };
        headEle.appendChild(JSONP);
    });

};

export default httpJsonp;