import {Module, Mutation, VuexModule} from "vuex-class-modules";
import store from "../plugins/store";

@Module
class LoadingStore extends VuexModule {
    private loadingCount: number = 0;

    get getLoadingVisible(): boolean {
        return this.loadingCount > 0;
    }

    @Mutation
    public setLoading() {
        this.loadingCount++;
    }

    @Mutation
    public resetLoading() {
        this.loadingCount--;
    }
}

export const loadingStore = new LoadingStore({store, name: "loading-store"});
