import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify, {VApp, VBtn, VCard, VCardActions, VCardText, VCardTitle, VDataTable, VDialog, VSnackbar, VSpacer,} from 'vuetify/lib';
import 'vuetify/src/styles/main.sass'
import 'vuetify/dist/vuetify.min.css'
import UploadIcon from "../icons/UploadIcon.vue";
import SzufIcon from '../icons/SzufIcon.vue';
import CegeljarasIcon from "../icons/CegeljarasIcon.vue";

Vue.use(Vuetify);

const vuetify = new Vuetify(
    {
        icons: {
            iconfont: 'fa',
            values: {
                fileUpload: {
                    component: UploadIcon
                },
                szuf: {
                    component: SzufIcon,
                },
                cegeljaras: {
                    component: CegeljarasIcon,
                },
            },
        },
        theme: {
            themes: {
                light: {
                    primary: '#004b88',
                    secondary: '#337ab7',
                    accent: '#EDAFB8',
                    error: '#c20029',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FFCC00',
                }
            }
        },
        components: {
            VApp,
            VBtn,
            VCard,
            VCardTitle,
            VCardText,
            VCardActions,
            VDialog,
            VSnackbar,
            VSpacer,
            VDataTable,
        },
    }
)

export default vuetify;
