
import {Component, Vue} from 'vue-property-decorator';
import {menuStore} from "../store/menu-store";
import router from "../router/router";
import errorHandler from "../error/error-handler";

@Component({
        components: {},
    })
    export default class CustomHeader extends Vue {

        public showDrawers() {
            menuStore.drawersVisible(true);
        }

        public navigateToContactPage() {
            router.push({name: "contact"});
        }

    public notImplementedYet() {
        errorHandler.showError("language.not-implemented");
    }

    get isShown() {
        return menuStore.drawerIsShown;
    }

    get isSmallMode() {
        return menuStore.smallMode;
    }
}
