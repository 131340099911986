import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {loginStore} from "../store/login-store";
import {loadingStore} from "../store/loading-store";
import errorHandler from "../error/error-handler";
import i18n from "./i18n";

Vue.use(VueAxios, axios);

const RENEW_TOKEN = 'renew-token';

const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 300000, // 5 mins
    headers: {'Content-Type': 'application/json'}
});

http.interceptors.request.use(
    (config) => {
        loadingStore.setLoading();
        const token = loginStore.jwtToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['Accept-Language'] = i18n.locale
        }
        return config;
    },
    (error) => {
        loadingStore.resetLoading();
        errorHandler.handleError(error);
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => {
        loadingStore.resetLoading();
        const renewToken = response.headers[RENEW_TOKEN];
        if (renewToken) {
            loginStore.login(renewToken);
        }
        return response;
    },
    (error) => {
        loadingStore.resetLoading();
        errorHandler.handleError(error);
        if (error?.response) {
            const status = error.response.status;
            if (status === 401) {
                loginStore.logout();
            }
        }
        return Promise.reject(error);
    }
);

export default http;
