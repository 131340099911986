export function log(message: string, level?: 'info' | 'warn' | 'error') {
    const env = process.env.NODE_ENV;

    if (env !== 'production') {
        if (level === 'error') {
            console.error(message);
        } else if (level === 'warn') {
            console.warn(message);
        } else {
            console.log(message);
        }
    }
}
