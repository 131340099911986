
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Menu} from "../common/menu";
import {menuStore} from "../store/menu-store";

@Component({
    components: {
        TreeMenu: () => import('./TreeMenu.vue')
    },
})
export default class TreeMenu extends Vue {
    @Prop()
    public menu?: Menu;
    @Prop()
    public depth?: number;
    public showChildren: boolean = false;

    get isSmallMode() {
        return menuStore.smallMode;
    }

    public layout() {
        if (this.menu) {
            return {
                'has-children': this.menu.submenus,
            };
        }
    }

    public iconClass() {
        return {
            'fa-plus': !this.showChildren,
            'fa-minus': this.showChildren
        }
    }


    public labelStyle() {
        const cssClass = {} as any;
        if (this.menu && this.menu.submenus) {
            cssClass['font-weight'] = 'bold';
        }
        cssClass['text-transform'] = 'uppercase';

        if (this.depth) {
            cssClass['padding-left'] = this.depth * 10 + `px`;
        }

        return cssClass;
    }

    public handleClick() {
        if (this.menu) {
            if (this.menu.submenus) {
                this.showChildren = !this.showChildren;
            } else {
                if (this.menu.url) {
                    this.$router.push(this.menu.url).catch((err: any) => {
                        // do nothing
                    });
                    menuStore.menuClicked();
                }
                if (this.menu.redirectUrl) {
                    window.open(this.menu.redirectUrl);
                }
            }
        }
    }

}
