
import {Component, Vue, Watch} from "vue-property-decorator";
import {DIALOG_HIDDEN, DIALOG_SHOWN, DIALOG_STAY_HIDDEN, JwtUser, loginStore} from "../store/login-store";
import {authService} from "../service/auth-service";
import {numberPad} from "../common/string-utility";
import {log} from "../common/log-utility";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import errorHandler from "../error/error-handler";

@Component({
    components: {ConfirmationDialog}
})
export default class UserWidget extends Vue {

    private readonly MINUTE_IN_SECONDS = 60;
    private readonly LOGOUT_WARNING_TIME = this.MINUTE_IN_SECONDS;
    private readonly LOGOUT_COUNTDOWN_INTERVAL = 1000;

    private logoutCountdownHandle: NodeJS.Timeout | undefined;
    private stayLoggedInDialog: boolean | null = loginStore.stayLoggedIn;

    private loggedInUser = loginStore.user;
    private logoutTime = loginStore.getTokenExpiry()

    public mounted() {
        loginStore.subscribe(this.userChangeHandler)
    }

    public beforeDestroy() {
        loginStore.unsubscribe(this.userChangeHandler);
        this.cancelLogoutCountdown();
    }

    get countdownTime() {
        return {
            hours: numberPad(~~(this.logoutTime / this.MINUTE_IN_SECONDS), 2),
            minutes: numberPad(~~(this.logoutTime % this.MINUTE_IN_SECONDS), 2)
        }
    }

    @Watch('logoutTime', {immediate: true})
    logoutCountdown(newValue: number) {
        if (newValue > 0) {
            if (newValue <= this.LOGOUT_WARNING_TIME && this.loggedInUser && this.stayLoggedInDialog !== DIALOG_STAY_HIDDEN) {
                log('Show dialog to let the user stay logged in');
                this.stayLoggedInDialog = DIALOG_SHOWN;
            } else if (newValue > this.LOGOUT_WARNING_TIME && this.loggedInUser && this.stayLoggedInDialog === DIALOG_SHOWN) {
                this.stayLoggedInDialog = DIALOG_HIDDEN;
            }

            if (this.logoutCountdownHandle === undefined) {
                this.logoutCountdownHandle = setInterval(() => {
                    loginStore.setTokenExpiry(--this.logoutTime);
                }, this.LOGOUT_COUNTDOWN_INTERVAL);
            }
        } else if (newValue == 0 && this.loggedInUser) {
            if (this.stayLoggedInDialog === DIALOG_SHOWN) {
                this.stayLoggedInDialog = DIALOG_HIDDEN;
            }
            log('Logging out because of countdown.');
            this.logout();
        }
    }

    public login() {
        authService.login().then((response) => {
            log('Logging in...');
            loginStore.login(response);
        }).catch(() => errorHandler.showError("error.authentication-failed"));
    }

    public logout() {
        log('Logging out...');
        loginStore.logout()
    }

    private cancelLogoutCountdown() {
        if (this.logoutCountdownHandle) {
            clearInterval(this.logoutCountdownHandle);
            this.logoutCountdownHandle = undefined;
            log('Logout countdown cancelled.');
        }
        this.logoutTime = 0;
    }

    private userChangeHandler(user: JwtUser | null) {
        if (user) {
            this.logoutTime = loginStore.getTokenExpiry();
            this.loggedInUser = user;
        } else {
            this.loggedInUser = null;
            this.cancelLogoutCountdown();
        }
        this.stayLoggedInDialog = loginStore.stayLoggedIn;
    }

    private onConfirm() {
        authService.stayLoggedIn();
        this.closeDialog(DIALOG_HIDDEN);
    }

    private closeDialog(value: boolean | null) {
        // Null value means that the dialog will stay hidden
        this.stayLoggedInDialog = value;
        loginStore.setStayLoggedIn(value);
    }
}
