export const menu: Menu[] = [
    {
        name: 'menu.company',
        icon: '$vuetify.icons.cegeljaras',
        iconSmall: true,
        url: '/',
        submenus: [
            {
                name: 'menu.company',
                submenus: [
                    {
                        name: 'menu.document-upload',
                        url: '/upload-document'
                    },
                    {
                        name: 'menu.documents',
                        url: '/documents'
                    },
                    {
                        name: 'menu.announcements',
                        url: '/announcements'
                    },
                    {
                        name: 'menu.email-management',
                        url: '/emails'
                    },
                ]
            },
            {
                name: 'menu.injunction-takeover',
                url: '/injunction-takeover'
            },
            {
                name: 'menu.company-form',
                url: '/company-form-validator'
            },
            {
                name: 'menu.xml-upload',
                url: '/xml-upload'
            },
            {
                name: 'menu.cgt',
                submenus: [
                    {
                        name: 'menu.cgt-guide',
                        url: '/cgt-guide'
                    },
                    {
                        name: 'menu.cgt-form',
                        url: '/cgt-form'
                    },
                ]
            },
            {
                name: 'menu.company-information',
                submenus: [
                    {
                        name: 'menu.authentic-company-information',
                        redirectUrl: 'https://occsz.e-cegjegyzek.hu',
                    },
                    {
                        name: 'menu.non-business-purpose-company-information',
                        submenus: [
                            {
                                name: 'menu.free-company-information',
                                redirectUrl: 'https://www.e-cegjegyzek.hu/?cegkereses',
                            },
                            {
                                name: 'menu.search-for-person',
                                redirectUrl: 'https://www.e-cegjegyzek.hu/?szemelykereses',
                            },
                            {
                                name: 'menu.forbidden-persons',
                                redirectUrl: 'https://www.e-cegjegyzek.hu/?eltiltottkereses',
                            },
                            {
                                name: 'menu.published-statements',
                                redirectUrl: 'http://www.cegkozlony.hu/',
                            },
                            {
                                name: 'menu.insight',
                                url: '/insight',
                            },
                            {
                                name: 'menu.insolveny-register',
                                redirectUrl: 'https://fizeteskeptelenseg.im.gov.hu/#/',
                            },
                            {
                                name: 'menu.money-article',
                                redirectUrl: 'https://e-beszamolo.im.gov.hu/oldal/beszamolo_kereses',
                            },
                        ],
                    },
                    {
                        name: 'menu.business-purpose-company-information',
                        submenus: [
                            {
                                name: 'menu.individual-contract',
                                redirectUrl: 'https://ceginformaciosszolgalat.kormany.hu/egyedi-on-line-szerzodes',
                            },
                            {
                                name: 'menu.distribution-contract',
                                redirectUrl: 'https://ceginformaciosszolgalat.kormany.hu/disztributori-szerzodes',
                            },
                        ]
                    },
                    {
                        name: 'menu.government-organisation',
                        redirectUrl: 'https://gov.e-cegjegyzek.hu/',
                    },
                ]
            }
        ]
    },
    {
        name: 'menu.maintenance',
        url: '/court-operation',
        icon: 'fas fa-wrench',
        iconSmall: true,
        submenus: [
            {
                name: 'menu.court-operation',
                url: '/court-operation',
            }
        ]
    },
    {
        name: 'menu.szuf',
        icon: '$vuetify.icons.szuf',
        iconSmall: true,
        redirectUrl: 'https://mo.hu',
    },
    {
        name: 'menu.storage',
        icon: 'far fa-envelope',
        iconSmall: true,
        redirectUrl: 'https://tarhely.gov.hu',
    },
    {
        name: 'menu.help.main',
        icon: 'far fa-question-circle',
        iconSmall: true,
        url: '/help',
        submenus: [
            {
                name: 'menu.help.company-registration.main',
                submenus: [
                    {
                        name: 'menu.help.company-registration.overview',
                        url: '/help/company-registration'
                    },
                    {
                        name: 'menu.help.company-registration.technical-conditions',
                        url: '/help/technical-conditions'
                    },
                    {
                        name: 'menu.help.company-registration.fee',
                        url: '/help/fee'
                    },
                    {
                        name: 'menu.help.company-registration.software-guide',
                        url: '/help/software-guide'
                    },
                    {
                        name: 'menu.help.company-registration.injunction-takeover',
                        url: '/help/injunction-takeover',
                    },
                    {
                        name: 'menu.help.company-registration.company-form',
                        url: '/help/company-form'
                    },
                    {
                        name: 'menu.help.company-registration.courts',
                        url: '/help/courts'
                    },
                    {
                        name: 'menu.help.company-registration.download',
                        url: '/help/download'
                    }
                ]
            },
            {
                name: 'menu.help.ban-announce.main',
                submenus: [
                    {
                        name: 'menu.help.ban-announce.overview',
                        url: '/help/ban-announce'
                    },
                    {
                        name: 'menu.help.ban-announce.apply-report',
                        url: '/help/ban-announce-apply-report'
                    },
                    {
                        name: 'menu.help.ban-announce.comparison',
                        url: '/help/ban-announce-comparison'
                    },
                    {
                        name: 'menu.help.ban-announce.new-branch',
                        url: '/help/ban-announce-new-branch'
                    },
                    {
                        name: 'menu.help.ban-announce.download',
                        url: '/help/ban-announce-download'
                    },
                ]
            },
            {
                name: 'menu.help.faq',
                url: '/help/faq'
            },
            {
                name: 'menu.help.legislation',
                url: '/help/legislation'
            },
            {
                name: 'menu.help.contact',
                url: '/contact'
            },
            {
                name: 'menu.help.links',
                url: '/help/links'
            }
        ]
    },
];

export interface Menu {
    name: string;
    icon?: string;
    iconSmall?: boolean;
    url?: string;
    redirectUrl?: string;
    submenus?: Menu[];
}
