
import {Component, Vue} from 'vue-property-decorator';
import TreeMenu from "./TreeMenu.vue";
import UserWidget from "./UserWidget.vue";
import {menuStore} from "../store/menu-store";
import router from "../router/router";

@Component({
    components: {UserWidget, TreeMenu},
})
export default class SubDrawer extends Vue {
    [x: string]: any;

    public state = true;

    get isShown() {
        return menuStore.drawerIsShown;
    }

    set isShown(state: boolean) {
        // do nothing (eslint error)
    }

    get isSmallMode() {
        return menuStore.smallMode;
    }

    get menu() {
        return menuStore.getSelectedMainMenu;
    }

    redirectToHome() {
        if (router.currentRoute.path !== '/') {
            router.push('/');
        }
    }
}
