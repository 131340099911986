/**
 * Left pad the given string (at {@code value} parameter) to the given length (at {code paddingLength} parameter) with the
 * specified character (at {@code paddingCharacter} parameter).
 *
 * @param value The string to pad out.
 * @param paddingLength The expected length of the output. If the length of the input is greater or equal than the value
 * of this parameter, then the output will be the input.
 * @param paddingCharacter The character to pad with.
 * @return The padded string.
 */
export function stringPad(value: string, paddingLength: number, paddingCharacter = "0") {
    if (value.length >= paddingLength) {
        return value;
    }

    const paddingString = new Array(paddingLength + 1).join(paddingCharacter);

    return (paddingString + value).slice(-paddingString.length);
}

/**
 * Left pad the given number (at {@code value} parameter) to the given length (at {code paddingLength} parameter) with the
 * specified character (at {@code paddingCharacter} parameter).
 *
 * @param value The number to pad out.
 * @param paddingLength The expected length of the output. If the length of the input as string is greater or equal than
 * the value of this parameter, then the output will be the input.
 * @param paddingCharacter The character to pad with.
 * @return The padded number as string.
 */
export function numberPad(value: number, paddingLength: number, paddingCharacter = "0") {
    return stringPad(String(value), paddingLength, paddingCharacter);
}
