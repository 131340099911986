
import MainDrawer from './components/MainDrawer.vue';
import SubDrawer from './components/SubDrawer.vue';
import {Component, Vue} from 'vue-property-decorator';
import {menuStore} from "./store/menu-store";
import CustomHeader from "./components/CustomHeader.vue";
import CustomFooter from "./components/CustomFooter.vue";
import LoadingComponent from "./components/LoadingComponent.vue";

@Component({
  components: {
    LoadingComponent,
    CustomFooter,
    CustomHeader,
    MainDrawer,
    SubDrawer
  },
})
export default class App extends Vue {
  [x: string]: any;

  public created() {
      menuStore.setMenuToMain();
      menuStore.smallModeSet(this.$vuetify.breakpoint.smAndDown);
  }

  public mounted() {
    window.onresize = (e: any) => {
      if (e.isTrusted) {
          menuStore.smallModeSet(window.innerWidth < this.$vuetify.breakpoint.thresholds.sm);
      }
    };
  }

}
