import Vue from "vue";
import i18n from "../plugins/i18n";
import {Error, ErrorResponse} from "../common/dto/error";

class ErrorHandler {
    public handleError(error: any) {
        if (error.response && error.response.status == 401) {
            this.showError("error.forbidden");
        } else {
            const response = error.response?.data as ErrorResponse;
            if (response) {
                const errors: Error[] = response.errors as Error[] || [];
                errors.forEach((err: Error) => {
                    this.showError(err.messageKey, err.parameters);
                });
            } else if (error?.config?.url?.includes('/login/cert')) {
                this.showError("error.authentication-failed");
            } else {
                this.showError("error.unreachable");
            }
        }
    }

    public showError(messageKey: string, params: {} = {}) {
        Vue.$toast.error(i18n.t(messageKey, params));
    }

    public showWarning(messageKey: string, params: {} = {}) {
        Vue.$toast.warning(i18n.t(messageKey, params));
    }

    public showSuccess(messageKey: string, params: {} = {}) {
        Vue.$toast.success(i18n.t(messageKey, params));
    }

    public showInfo(messageKey: string, params: {} = {}) {
        Vue.$toast.success(i18n.t(messageKey, params));
    }
}

const errorHandler = new ErrorHandler()

export default errorHandler;
