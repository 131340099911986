
import {Component, Vue} from 'vue-property-decorator';
import VueLoading from 'vue-loading-overlay/src/js/Component.vue';
import 'vue-loading-overlay/dist/vue-loading.css';
import {loadingStore} from '../store/loading-store';

@Component({
  components: {VueLoading},
})
export default class LoadingComponent extends Vue {
  get isVisible() {
    return loadingStore.getLoadingVisible
  }
}
