
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ConfirmationDialog extends Vue {
  @Prop({default: ""}) text!: string;
  @Prop({default: null}) dialog!: boolean | null;

  private accept() {
    this.$emit('confirmed');
  }

  private decline() {
    this.$emit('closeDialog', null);
  }
}
