import Vue from 'vue';
import Router, {Route} from 'vue-router';
import {NavigationGuardNext} from "vue-router/types/router";
import {loginStore} from "../store/login-store";
import {authService} from "../service/auth-service";
import errorHandler from "../error/error-handler";

Vue.use(Router);

const router = new Router({
    scrollBehavior: (to, from, savedPosition) => {
        return {x: 0, y: 0}
    },
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import("@/views/Home.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/email/confirm',
            name: 'email-confirmation',
            component: () => import("@/views/EmailConfirmation.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/court-operation',
            name: 'court-operation',
            component: () => import("@/views/CourtOperation.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import("@/views/Contact.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/download/announcement/:id/:hash',
            name: 'download-announcement',
            component: () => import("@/views/AnnouncementDownload.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/announcements',
            name: 'announcements',
            component: () => import('@/views/Announcements.vue'),

        },
        {
            path: '/documents',
            name: 'documents',
            component: () => import("@/views/Documents.vue"),
        },
        {
            path: '/upload-document',
            name: 'upload-document',
            component: () => import("@/views/DocumentSubmission.vue"),
        },
        {
            path: '/emails',
            name: 'email-management',
            component: () => import("@/views/EmailManagement.vue"),
        },
        {
            path: '/company-form-validator',
            name: 'company-form',
            component: () => import("@/views/CompanyFormValidator.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/xml-upload',
            name: 'xml-upload',
            component: () => import("@/views/CreationDocumentXmlSubmission.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/cgt-guide',
            name: 'cgt-guide',
            component: () => import("@/views/CgtGuide.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/cgt-form',
            name: 'cgt-form',
            component: () => import("@/views/CgtForm.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/injunction-takeover',
            name: 'injunction-takeover',
            component: () => import("@/views/InjunctionTakeover.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/insight',
            name: 'insight',
            component: () => import("@/views/Insight.vue"),
            meta: {
                isPublic: true
            }
        }, {
            path: '/company-search',
            name: 'company-search',
            component: () => import("@/views/CompanySearch.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help',
            name: 'help',
            component: () => import("@/views/Help/Help.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/company-registration',
            name: 'company-registration',
            component: () => import("@/views/Help/HelpCompanyRegistrationOverview.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/technical-conditions',
            name: 'technical-conditions',
            component: () => import("@/views/Help/HelpTechnicalConditions.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/company-form',
            name: 'company-form-help',
            component: () => import("@/views/Help/HelpCompanyForm.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/software-guide',
            name: 'software-guide',
            component: () => import("@/views/Help/HelpSoftwareGuide.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/injunction-takeover',
            name: 'help-injunction-takeover',
            component: () => import("@/views/Help/HelpInjunctionTakeover.vue"),
            meta: {
                isPublic: true,
            },
        },
        {
            path: '/help/download',
            name: 'download',
            component: () => import("@/views/Help/HelpDownload.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/fee',
            name: 'fee',
            component: () => import("@/views/Help/HelpFee.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/courts',
            name: 'courts',
            component: () => import("@/views/Help/HelpCourts.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/ban-announce',
            name: 'ban-announce',
            component: () => import("@/views/Help/HelpBanAnnounceOverview.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/ban-announce-apply-report',
            name: 'ban-announce-apply-report',
            component: () => import("@/views/Help/HelpBanAnnounceApplyReport.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/ban-announce-comparison',
            name: 'ban-announce-comparison',
            component: () => import("@/views/Help/HelpBanAnnounceComparison.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/ban-announce-new-branch',
            name: 'ban-announce-new-branch',
            component: () => import("@/views/Help/HelpBanAnnounceNewBranch.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/ban-announce-download',
            name: 'ban-announce-download',
            component: () => import("@/views/Help/HelpBanAnnounceDownload.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/faq',
            name: 'faq',
            component: () => import("@/views/Help/HelpFAQ.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/legislation',
            name: 'legislation',
            component: () => import("@/views/Help/HelpLegislation.vue"),
            meta: {
                isPublic: true
            }
        },
        {
            path: '/help/links',
            name: 'links',
            component: () => import("@/views/Help/HelpLinks.vue"),
            meta: {
                isPublic: true
            }
        },
        {path: '/404', component: () => import("@/components/NotFound.vue"), meta: {isPublic: true}},
        {path: '*', redirect: '/404'},
    ],
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
        if (!to?.meta?.isPublic && !loginStore.isLoggedIn) {

            authService.login().then((response) => {
                loginStore.login(response);
            })
                .then(() => {
                    if (!loginStore.isLoggedIn) {
                        errorHandler.showError("error.authentication-required")
                        // If the user enters an URL directly, which needs authentication, navigate the user to the home page
                        if (!from.name) {
                            next({name: 'home'})
                        }
                        return false;
                    }
                    next();
                })
                .catch(() => errorHandler.showError("error.authentication-failed"));
        } else {
            next();
        }
    }
)

export default router;

