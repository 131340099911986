import {loadingStore} from "../store/loading-store";
import http from "../plugins/http";
import httpJsonp from "../plugins/httpJsonp";

export default class AuthService {
    private certLoginHost = null;

    public async login() {
        if (this.certLoginHost === null) {
            await this.getCertLoginHost();
        }
        return this.certLogin();
    }

    public certLogin() {
        loadingStore.setLoading();
        return httpJsonp(`${this.certLoginHost}/login/cert`, () => {
            loadingStore.resetLoading();
        });
    }

    public stayLoggedIn() {
        return http.get("/login/stayLoggedIn");
    }

    public getCertLoginHost() {
        return http.get("/login/certLoginHost").then((response) => {
            this.certLoginHost = response.data;
        });
    }
}
export const authService = new AuthService();