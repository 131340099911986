import Vue from 'vue'
import VueI18n from "vue-i18n";
import hu from '../translation/hu.json';

Vue.use(VueI18n)

enum Locales {
    HU = 'hu',
    EN = 'en',
}

export const messages = {
    [Locales.HU]: hu,
};

const i18n = new VueI18n({
    messages,
    locale: Locales.HU,
    fallbackLocale: Locales.HU
});

export default i18n;
