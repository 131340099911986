import {menu, Menu} from "../common/menu";
import {Module, Mutation, VuexModule} from "vuex-class-modules";
import store from "../plugins/store";

@Module
class MenuStore extends VuexModule {
    [x: string]: any;

    private selectedMainMenu: Menu = {} as Menu;
    private show = true;
    private menus: Menu[] = [];
    private small = false;

    setMenuToMain() {
        const selectedMenu = menu[0];
        this.setSelectedMainMenu(selectedMenu);
        this.changeMenu(selectedMenu.submenus!);
    }

    @Mutation
    setSelectedMainMenu(menu: Menu) {
        this.selectedMainMenu = menu;
    }

    @Mutation
    drawersVisible(state: boolean) {
        this.show = state;
    }

    get smallMode() {
        return this.small;
    }

    @Mutation
    changeMenu(menu: Menu[]) {
        this.menus = menu;
    }

    @Mutation
    menuClicked() {
        if (this.small) {
            this.show = false;
        }
    }

    get drawerIsShown() {
        return this.show;
    }

    get subdrawerMenu() {
        return this.menus;
    }

    get getSelectedMainMenu() {
        return this.selectedMainMenu;
    }

    @Mutation
    smallModeSet(state: boolean) {
        if (state != this.small) {
            this.show = !state;
        }

        this.small = state;
    }
}

export const menuStore = new MenuStore({store, name: "menu-store"});
