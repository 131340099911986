import Vue from 'vue'
import App from './App.vue'
import router from "./router/router";
import store from "./plugins/store";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMask from 'v-mask'

const options = {
    transition: "Vue-Toastification__fade",
    filterBeforeCreate: (toast: any, toasts: any[]) => {
        const sameToast = toasts.find(t => t.content === toast.content && t.type === toast.type)
        if (sameToast) {
            return false;
        }

        return toast;
    }
};

Vue.config.productionTip = false
Vue.use(Vuetify);
Vue.use(Toast, options);
Vue.use(VueMask);

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app')
