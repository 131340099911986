
import {Component, Vue} from 'vue-property-decorator';
import SubDrawer from './SubDrawer.vue';
import * as menus from '../common/menu';
import {Menu} from '../common/menu';
import {menuStore} from "../store/menu-store";


@Component({
    components: {SubDrawer},
})
export default class MainDrawer extends Vue {
    [x: string]: any;

    public menus: Menu[] = menus.menu;
    public subMenus: Menu[] | null = null;

    constructor() {
        super();
        this.selectMenuAfterPageLoad();
    }

    public menuClicked(menu: Menu) {
        if (menu.redirectUrl) {
            window.open(menu.redirectUrl);
        } else {
            menuStore.setSelectedMainMenu(menu)
            menuStore.changeMenu(menu.submenus!)
            if (menu.url) {
                this.$router.push(menu.url).catch((err: any) => {
                    // suppress console errors
                });
            }
        }
    }

    get isShown() {
        return menuStore.drawerIsShown;
    }

    get isSmallMode() {
        return menuStore.smallMode;
    }

    get getSelectedMainMenu() {
        return menuStore.getSelectedMainMenu;
    }

    public collapseDrawers() {
        menuStore.drawersVisible(!menuStore.drawerIsShown);
    }

    private selectMenuAfterPageLoad() {
        // If the route we are currently at is a lazy-loaded component, then we have to wait for the router to be ready
        this.$router.onReady(() => {
            const actualRoute: string = this.$route.name!;
            const selectedMenu: Menu | undefined = menus.menu.find(menu => {
                return this.includesRoute(menu, actualRoute);
            });
            if (selectedMenu) {
                menuStore.setSelectedMainMenu(selectedMenu);
            } else {
                menuStore.setSelectedMainMenu(menus.menu.find(menu => menu.name == 'menu.company')!);
            }
        });
    }

    private includesRoute(menu: Menu, route: string): boolean {
        if (menu.name?.includes(route)) {
            return true;
        } else if (menu.submenus) {
            return menu.submenus.some(submenu => this.includesRoute(submenu, route));
        }

        return false;
    }
}
